<script setup>
const bgStyles = {
  backgroundImage:
    'url("https://res.cloudinary.com/benchmarkminerals/image/upload/f_auto,q_auto/v1672543364/source-assets/homepage_newsletter_banner_sho2y4_o8xmzl.avif?_i=AA")',
  backgroundPosition: 'center',
}
</script>

<template>
  <section
    class="relative mt-14 w-full bg-cover py-20 md:mt-25"
    :style="bgStyles"
    data-testid="newsletter-section"
  >
    <div class="relative px-5">
      <div class="mx-auto flex max-w-4xl flex-col items-center p-8">
        <p class="mb-2 text-center text-3xl font-bold text-white">
          Don’t miss out on the latest developments
        </p>
        <p class="mb-3 w-full text-center text-lg text-white">
          Sign up to our Newsletter to get updates on content that’s relevant to
          you
        </p>
        <BaseButton class="mb-3 px-10" @click="$modal.open('subscribe')">
          Sign up
        </BaseButton>
      </div>
    </div>
  </section>
</template>
