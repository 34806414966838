<script setup lang="ts">
import { PropType } from 'vue'
import { Category, Tag } from '@/models/content'

const props = defineProps({
  slug: {
    type: [String, Number],
    required: true,
  },
  publishedDate: {
    type: String,
    required: true,
  },
  duration: {
    type: Number,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  excerpt: {
    type: String,
    required: true,
  },
  pills: {
    type: Array as PropType<Tag[] | Category[]>,
    required: true,
  },
  coverUrl: {
    type: String,
    required: true,
  },
})

const href = useContentHref('video', props.slug)
const coverPosition = useCoverPosition(props.coverUrl)
</script>

<template>
  <BaseLink :href="href" as="a" variant="unstyled" class="h-full">
    <article class="card-hover-md relative h-full w-full">
      <!-- Cover -->
      <div class="absolute top-0 left-0 h-full w-full">
        <img
          width="500"
          height="500"
          :src="$cloudinary.resize(coverUrl, 'featured-video-thumbnail')"
          class="h-full w-full object-cover"
          :class="coverPosition"
          alt=""
        />
      </div>

      <!-- Overlay -->
      <div class="absolute top-0 left-0 h-full w-full bg-black opacity-50" />

      <!-- Badge -->
      <div class="absolute right-1 top-1 flex md:top-3 md:right-3">
        <VideoBadge />
      </div>

      <!-- Info -->
      <div class="relative p-14 py-28 text-white">
        <FeaturedGeneralCard
          :published-date="publishedDate"
          content-type="video"
          :slug="slug"
          :duration="duration"
          :title="title"
          :excerpt="excerpt"
          :pills="pills"
        />
      </div>
    </article>
  </BaseLink>
</template>
