<script setup lang="ts">
import { PropType } from 'vue'
import { ContentType } from '@/models/content'
import { titleCase } from '@/utils/string'

const props = defineProps({
  contentType: {
    type: String as PropType<ContentType>,
    required: true,
    validator(value: string) {
      return ['presentation', 'magazine'].includes(value)
    },
  },
  publishedDate: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  quarter: {
    type: String,
    required: false,
  },
  issue: {
    type: String,
    required: false,
  },
  coverUrl: {
    type: String,
    required: true,
  },
  fileName: {
    type: String,
    required: true,
  },
  fileCategory: {
    type: String,
    required: true,
  },
})

const { callToAction, isDownloading, downloadProgress } = useDownloadFile()

const isMagazine = computed(() => props.contentType === 'magazine')
const coverPosition = useCoverPosition(props.coverUrl)

const formattedTitle = computed(() =>
  isMagazine.value
    ? `${props.quarter} | ${props.title} - ${props.issue}`
    : props.title,
)
</script>

<template>
  <article
    class="card-hover group flex w-full flex-col items-start gap-x-3 pb-4 pt-2 sm:pr-4 lg:flex-row lg:pt-4"
    @click="callToAction.callback(id, fileCategory, fileName)"
  >
    <div
      class="flex w-full flex-col items-start gap-x-3 sm:flex-row sm:items-center lg:items-start"
    >
      <div
        class="relative h-44 w-full shrink-0 sm:h-14 sm:w-25 lg:h-28 lg:w-48"
      >
        <ClientOnly>
          <DownloadOverlay
            :is-downloading="isDownloading"
            :download-progress="downloadProgress"
            :label="callToAction.text"
          />
        </ClientOnly>

        <img
          width="500"
          height="500"
          :src="$cloudinary.resize(coverUrl, 'horizontal-card-thumbnail')"
          class="h-full min-w-full object-cover"
          :class="coverPosition"
          alt=""
        />

        <div class="absolute top-0 h-full w-full border-2 border-black/10" />

        <div class="absolute right-3 top-3 hidden lg:flex">
          <ContentTypeBadge :type="contentType" />
        </div>
      </div>

      <div class="flex items-center pt-2 sm:pt-0">
        <div class="pr-1 lg:hidden">
          <ContentTypeBadge :type="contentType" icon-gray />
        </div>
        <div class="flex sm:flex-col">
          <p class="mb-0.5 text-xs font-semibold text-s-500">
            {{ titleCase(contentType) }}
            <span class="sm:hidden">-&nbsp;</span>
          </p>
          <p class="text-xs font-semibold text-s-500">
            {{ publishedDate }}
          </p>
          <h3
            class="mt-1 hidden text-lg font-semibold leading-snug md:mt-1 md:text-xl md:font-bold lg:block"
          >
            {{ formattedTitle }}
          </h3>
        </div>
      </div>
    </div>
    <!-- mobile -->
    <div class="lg:hidden">
      <h3
        class="mt-2 text-lg font-semibold leading-snug md:mt-1 md:text-xl md:font-bold"
      >
        {{ formattedTitle }}
      </h3>
    </div>
  </article>
</template>
