<script setup lang="ts">
import { PropType } from 'vue'
import { Video } from '@/models/content'

const props = defineProps({
  featuredVideo: {
    type: Object as PropType<Video>,
    required: false,
  },
  videos: {
    type: Object as PropType<Video[]>,
    required: true,
  },
})

const MAX_VIDEOS = 3

const remainingVideos = ref<Video[]>([])
const firstVideo = ref<Video>()

const MAX_FEATURED_TAGS = 2

if (props.featuredVideo) {
  remainingVideos.value = props.videos.filter(
    (obj) => obj.id !== props.featuredVideo.id,
  )

  if (remainingVideos.value.length >= MAX_VIDEOS) {
    remainingVideos.value = remainingVideos.value.slice(0, 2)
  }
} else {
  remainingVideos.value = [...props.videos]
  firstVideo.value = remainingVideos.value.shift()
}

const maxFeaturedTags = computed(() => {
  return useFormattedPillsData(props.featuredVideo, MAX_FEATURED_TAGS)
})

const firstOrFeaturedVideo = computed(
  () => props.featuredVideo || firstVideo.value,
)
</script>

<template>
  <BaseSection title="Video" data-testid="video-section">
    <div class="relative mb-4 lg:grid lg:grid-cols-4 lg:gap-5">
      <section class="hidden h-full md:block lg:col-span-3">
        <FeaturedVideoCard
          v-if="firstOrFeaturedVideo"
          :published-date="firstOrFeaturedVideo.publishedDate"
          content-type="video"
          :slug="firstOrFeaturedVideo.slug"
          :duration="firstOrFeaturedVideo.duration"
          :title="firstOrFeaturedVideo.title"
          :excerpt="firstOrFeaturedVideo.excerpt"
          :pills="maxFeaturedTags"
          :cover-url="firstOrFeaturedVideo.coverUrl"
        />
      </section>

      <section
        class="flex flex-col gap-5 md:mt-10 md:grid md:grid-cols-2 lg:mt-0 lg:grid-cols-1 lg:grid-rows-2"
      >
        <VerticalCard
          v-if="firstOrFeaturedVideo"
          :published-date="firstOrFeaturedVideo.publishedDate"
          content-type="video"
          :slug="firstOrFeaturedVideo.slug"
          :duration="firstOrFeaturedVideo.duration"
          :title="firstOrFeaturedVideo.title"
          :excerpt="firstOrFeaturedVideo.excerpt"
          :pills="[]"
          :cover-url="firstOrFeaturedVideo.coverUrl"
          is-video
          class="md:hidden"
        />
        <VerticalCard
          v-for="video in remainingVideos"
          :key="video.id"
          :published-date="video.publishedDate"
          content-type="video"
          :slug="video.slug"
          :duration="video.duration"
          :title="video.title"
          :excerpt="video.excerpt"
          :pills="[]"
          :cover-url="video.coverUrl"
          is-video
        />
      </section>
    </div>

    <div class="mt-2 flex justify-center md:mt-10">
      <BaseLink
        variant="tertiary"
        href="/video"
        data-testid="see-more-videos-link"
      >
        <span class="md:hidden">See more</span>
        <span class="hidden md:block">Click to view more Videos</span>
      </BaseLink>
    </div>
  </BaseSection>
</template>
